// Here you can add other styles
.card-header-title {
    display: inline-block;
    vertical-align: middle;
}

// Resolves issue with Chrome deprecation of -webkit-appearance: listbox (which is applied by the date input form control
input#date,
input#time,
input#fromDate,
input#toDate,
input.form-control {
    -webkit-appearance: none;
}
